var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-6 mb-10 pb-10"},_vm._l((_vm.events),function(event,i){return _c('v-card',{key:i,staticClass:"my-4",staticStyle:{"cursor":"pointer"},attrs:{"tile":"","elevation":"4"},on:{"click":function($event){return _vm.$root.routeTo({
          name: 'EventCard',
          params: { no: event.no },
        })}}},[_c('v-row',{staticClass:"d-flex",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card',{attrs:{"height":"50%","tile":"","color":"secondary"}},[_c('div',{staticClass:"text-center text-h5 py-3 white--text"},[_vm._v(" "+_vm._s(new Date(event.startDate).getDate())+" "),_c('sup',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.getOrdinalSuffix(new Date(event.startDate).getDate())))])])]),_c('v-card',{staticClass:"text-center",attrs:{"height":"50%","tile":"","color":"primary"}},[_c('v-card-text',[_c('span',{staticClass:"text-uppercase text-h5",staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.months[new Date(event.startDate).getMonth()]))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('div',{staticClass:"ml-3 mt-4 mr-n2"},[_c('CPDbadge',{attrs:{"cpd-points":event.cpdHours}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"mx- mt-5"},[_c('div',{staticClass:"dateText d-md-block d-sm-flex flex-row justify-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(event.description))]),_vm._v(" | "),_c('span',{staticClass:"mr-2 text-body-1"},[_vm._v("From "+_vm._s(_vm.getFormattedDate(event.startDate))+" - "+_vm._s(_vm.getFormattedTime(event.startTime))+" To "+_vm._s(_vm.getFormattedDate(event.endDate))+" - "+_vm._s(_vm.getFormattedTime(event.endTime))+" ")])]),_c('div',{staticClass:"d-md-block d-sm-flex flex-row justify-center"},[_c('v-chip-group',{attrs:{"column":""}},[_c('v-chip',{staticClass:"white--text",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(event.eventType)+" ")]),_c('v-chip',{staticClass:"white--text",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(event.location)+" ")])],1)],1)])]),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"d-flex flex-row justify-center items-center",staticStyle:{"margin-top":"20%"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$root.routeTo({
                  name: 'EventCard',
                  params: { no: event.no },
                })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("book_online")]),_vm._v(" View details ")],1)],1)])],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }