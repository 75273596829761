<template>
  <div>
    <div class="text-lg-h6 mt-n1 d-flex justify-space-between">
      <div>Select Delegate Package</div>
      <div>
        <v-autocomplete
          :items="currencies"
          :label="`${currency === 'Local' ? '' : 'Select'} Currency`"
          outlined
          dense
          :disabled="currency === 'Local'"
          v-model="selectedCurrency"
        >
          <template v-slot:prepend-inner>
            <v-icon class="mt-2">mdi-cash</v-icon>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div>
      <div class="packagesPod">
        <v-chip-group
          v-model="selectedPackages"
          column
          multiple
          active-class="primary lighten-4 primary--text text--darken-4"
        >
          <v-chip
            filter
            v-for="(item, i) in eventPackages"
            :key="i"
            :disabled="(item.mandatory && item.defaultPackage) || !isFormValid"
          >
            {{ item.description }} :
            {{ currency !== "Local" ? item.currencyCode : "KES" }}
            <span v-if="isEarlyBird(item)" class="ml-1">
              {{
                (currency !== "Local"
                  ? item.earlyBirdAmountInclVAT
                  : item.earlyBirdAmountInclLCY) | currencyFormat
              }}
            </span>
            <span v-else class="ml-1">{{
              (currency !== "Local"
                ? item.amountIncludingVAT
                : item.amountInclLCY) | currencyFormat
            }}</span>
          </v-chip>
        </v-chip-group>
      </div>
    </div>
  </div>
</template>
<script>
import bookingMixin from "./bookingMixin";
export default {
  name: "DelegatePackages",
  mixins: [bookingMixin],
  props: {
    delegateForm: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      type: String,
      default: "Local",
    },
    category: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedPackages: [],
      currencies: ["KES"],
      eventPackages: [],
      categories: [],
      isBooking: false,
      selectedCurrency: null,
      selectedPackagesArray: [],
    };
  },
  mounted() {
    this.setPackages();
    if (this.$route.query.bookingNo && this.$route.params.id !== undefined) {
      this.isBooking = true;
    }
  },
  computed: {
    isFormValid() {
      return this.delegateForm.idNo && this.delegateForm.idNo.length > 0;
    },
    delegateTotal() {
      const total = {
        amountExclVAT: 0,
        amountInclVAT: 0,
        vatAmount: 0,
      };
      this.selectedPackages.forEach((pack) => {
        total.amountExclVAT += pack.amountExclVAT;
        total.amountInclVAT += pack.amountInclVAT;
        total.vatAmount += pack.vatAmount;
      });

      return total;
    },
  },
  methods: {
    isEarlyBird: function (eventPackage) {
      return new Date(eventPackage.earlyBirdCutoffDate) >= new Date();
    },
    setPackages: function () {
      if (this.isCorporateBooking) {
        this.eventPackages = this.event.eventPackageCost.filter(
          (p) => p.appliesType === "Corporate"
        );
      } else {
        this.eventPackages = this.event.eventPackageCost
          .filter((p) => p.attendanceType === this.delegateForm.attendanceType)
          .filter(
            (p) =>
              p.appliesType === this.delegateForm.delegateType ||
              p.appliesType === "" ||
              p.appliesType === " "
          )
          .filter(
            (p) =>
              p.membershipCategory === this.category ||
              p.membershipCategory === " " ||
              p.membershipCategory === ""
          );
      }

      this.eventPackages.forEach((p) => {
        if (!this.currencies.includes(p.currencyCode))
          this.currencies.push(p.currencyCode);

        if (p.membershipCategory !== "")
          if (!this.categories.includes(p.membershipCategory))
            this.categories.push(p.membershipCategory);
      });

      this.selectedCurrency = this.currencies[0];
    },

    updatePackage: function () {
      if (this.decryptedRoute.params.id !== undefined) {
        // updated selected packages by saved packages on edit
        const savedpackages = [];
        this.delegateForm.bookingDelegatesPackage.map((el, i) => {
          if (
            this.event.eventPackageCost.find(
              (a) => a.packageNo === el.packageCode
            ) !== undefined
          ) {
            savedpackages.push(i);
          }
        });
        this.selectedPackages = savedpackages;
      } else {
        this.selectedPackages = this.eventPackages
          .map((p, i) => {
            if (p.mandatory || p.defaultPackage) {
              return i;
            }
          })
          .filter((el) => el !== undefined);
      }
    },
    updateByCategory() {
      // filter for member delegates
      const isCategory = this.event.eventPackageCost.find(
        (p) => p.membershipCategory === this.category
      );

      this.eventPackages = this.event.eventPackageCost
        .filter((p) => p.attendanceType === this.delegateForm.attendanceType)
        .filter(
          (p) =>
            p.appliesType === this.delegateForm.delegateType ||
            p.appliesType === "" ||
            p.appliesType === " "
        )
        .filter((p) =>
          isCategory
            ? p.membershipCategory === this.category
            : p.membershipCategory === this.category ||
              p.membershipCategory === " " ||
              p.membershipCategory === ""
        );
    },
  },
  watch: {
    selectedPackages: {
      handler: function () {
        const selectedPackages = [
          ...this.selectedPackages.map((el) => {
            this.eventPackages[el].packageCode =
              this.eventPackages[el].packageNo;
            return this.eventPackages[el];
          }),
        ];
        this.$store.commit(
          "Events/SET_SELECTED_AMOUNT",
          selectedPackages.reduce((a, b) => {
            let amount = 0;
            if (this.isEarlyBird(b)) {
              if (this.currency !== "Local") {
                amount = b.earlyBirdAmountInclVAT;
              } else {
                amount = b.earlyBirdAmountInclLCY;
              }
            } else {
              if (this.currency !== "Local") {
                amount = b.amountIncludingVAT;
              } else {
                amount = b.amountInclLCY;
              }
            }
            return a + amount;
            //    a +
            //   (this.isEarlyBird(b)
            //     ? b.earlyBirdAmountInclVAT
            //     : b.amountIncludingVAT),
            // 0
          }, 0)
        );
        this.selectedPackagesArray = selectedPackages;
        this.$emit("addPackagesToFormData", this.selectedPackagesArray);
      },
      deep: true,
    },
    "delegateForm.delegateType": function () {
      if (!this.isCorporateBooking) {
        this.updateByCategory();
      }
    },
    "delegateForm.attendanceType": function () {
      if (!this.isCorporateBooking) {
        this.updateByCategory();
      }
    },
    category: function () {
      if (!this.isCorporateBooking) {
        this.updateByCategory();
      }
    },
    event: {
      handler: function () {
        this.setPackages();
      },
      deep: true,
    },
    eventPackages: {
      handler: function () {
        this.updatePackage();
      },
      deep: true,
    },
    selectedCurrency: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue && this.currency !== "Local") {
          this.selectedCurrency = newValue;
        } else {
          this.selectedCurrency = "KES";
        }
      },
    },
    currency: {
      handler: function (newValue) {
        if (newValue === "Local") {
          this.selectedCurrency = "KES";
        }
        const selectedPackages = [
          ...this.selectedPackages.map((el) => {
            this.eventPackages[el].packageCode =
              this.eventPackages[el].packageNo;
            return this.eventPackages[el];
          }),
        ];
        this.$store.commit(
          "Events/SET_SELECTED_AMOUNT",
          selectedPackages.reduce((a, b) => {
            let amount = 0;
            if (this.isEarlyBird(b)) {
              if (this.currency !== "Local") {
                amount = b.earlyBirdAmountInclVAT;
              } else {
                amount = b.earlyBirdAmountInclLCY;
              }
            } else {
              if (this.currency !== "Local") {
                amount = b.amountIncludingVAT;
              } else {
                amount = b.amountInclLCY;
              }
            }
            return a + amount;
            //    a +
            //   (this.isEarlyBird(b)
            //     ? b.earlyBirdAmountInclVAT
            //     : b.amountIncludingVAT),
            // 0
          }, 0)
        );
        this.selectedPackagesArray = selectedPackages;
        this.$emit("addPackagesToFormData", this.selectedPackagesArray);
      },
    },
  },
};
</script>
