<template>
  <v-container
    fluid
    class="mx-auto"
    ref="eventViewContainer"
    :style="$vuetify.breakpoint.mdAndUp ? 'width: 75%' : 'width: 100%'"
  >
    <v-card v-if="event.no !== decryptedRoute.params.no" flat>
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="card, list-item-three-line, card-heading, list-item-three-line"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="list-item-three-line, list-item-avatar, card-heading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" v-for="col in 5" :key="col">
          <v-skeleton-loader type="avatar" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="image" />
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="event.no === decryptedRoute.params.no" flat class="mt-2">
      <v-row>
        <v-col cols="12" md="7">
          <v-card flat>
            <v-img
              :src="url + event.base64Logo"
              :lazy-src="require(`@/assets/images/picture4.jpg`)"
              :height="$vuetify.breakpoint.mdAndUp ? '454px' : '224px'"
              :width="$vuetify.breakpoint.mdAndUp ? '800px' : '320px'"
              style="border-radius: 5px"
              class="mx-auto"
            />

            <v-card-title> Event Information </v-card-title>

            <v-card-text v-html="event.excerpt" />

            <v-card-title> Event Attachments </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  cols="4"
                  v-for="(attachment, i) in event.eventsDocuments"
                  :key="i"
                >
                  <v-sheet
                    :color="fileMeta(attachment.documentFormat).color"
                    dark
                    outlined
                    rounded
                  >
                    <v-list-item link @click="viewDoc(attachment)">
                      <v-list-item-icon>
                        <v-icon>
                          {{ fileMeta(attachment.documentFormat).icon }}
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ attachment.documentDescription }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ attachment.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <EventCountdown :start-date="new Date(event.startDate)" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="5">
          <v-card flat class="mx-auto">
            <v-row no-gutters>
              <v-col cols="12" md="10">
                <!-- Description -->
                <div
                  class="text-capitalize text-wrap text-lg-h4 font-weight-bold"
                >
                  {{ event.description }}
                </div>
                <!-- Date and time -->
                <div>
                  <v-icon small>mdi-calendar-multiselect</v-icon>
                  <span>
                    Upcoming: {{ getFormattedDate(event.startDate) }}
                  </span>
                  <br />

                  <div v-if="event.location !== 'Online'">
                    <a
                      class="text-capitalize mt-2 text-body-1 font-weight-bold text-justify text-decoration-none"
                      @click="
                        $vuetify.goTo('#map', {
                          duration: 500,
                          easing: 'easeInOutCubic',
                        })
                      "
                    >
                      <v-icon color="primary">mdi-map-marker</v-icon>
                      {{ event.location }}
                    </a>
                  </div>

                  <span v-else class="font-weight-bold">{{
                    event.location
                  }}</span>
                </div>
              </v-col>
              <v-col cols="12" md="2">
                <div style="width: 120%">
                  <CPDbadge :cpd-points="event.cpdHours" />
                </div>
              </v-col>
            </v-row>
            <!-- slots -->
            <v-list dense class="mt-n2">
              <v-list-item dense class="pa-0">
                <v-list-item-avatar color="amber lighten-4" size="30">
                  <v-icon small color="amber darken-4">ti-user</v-icon>
                </v-list-item-avatar>

                <v-list-item-content
                  class="stats"
                  v-if="parseInt(event.slotsAvailable) !== 0"
                >
                  <v-list-item-subtitle class="textStyle2">
                    {{ event.slotsAvailable }}
                    <span class="textStyle">Slots Available</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content class="stats" v-else>
                  <div class="textStyle">Slots Available</div>

                  <v-list-item-subtitle class="textStyle2">
                    {{ event.slots }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <!-- Buttons -->
            <v-btn
              v-if="hasCorporateOption"
              color="primary"
              class="toggleButtons mx-2"
              @click="bookingTypeDialog = true"
              block
            >
              <v-icon left>book_online</v-icon>
              Book Now
            </v-btn>
            <v-btn
              v-else
              @click="addDelegate()"
              color="primary"
              class="toggleButtons mx-2"
              block
            >
              <v-icon left>book_online</v-icon>
              Book Now
            </v-btn>

            <div class="text-center">
              <v-dialog v-model="bookingTypeDialog" width="auto" scrollable>
                <v-card>
                  <v-card-text class="mt-4 text-center font-weight-bold">
                    Select one of the booking types below.
                  </v-card-text>
                  <!--                                      <v-card-actions class="w-100">-->
                  <v-row class="d-flex justify-space-between w-100 mx-4 mb-4">
                    <div class="w-50 mr-4">
                      <v-btn
                        @click="addDelegate('Individual')"
                        color="primary"
                        class="toggleButtons w-50"
                        block
                      >
                        <v-icon left>book_online</v-icon>
                        Individual
                      </v-btn>
                    </div>

                    <div class="w-50">
                      <v-btn
                        @click="addDelegate('Corporate')"
                        color="primary"
                        class="toggleButtons"
                        block
                      >
                        <v-icon left>mdi-table-furniture</v-icon>
                        Corporate table
                      </v-btn>
                    </div>
                  </v-row>

                  <!--                    </v-card-actions>-->
                </v-card>
              </v-dialog>
            </div>
            <!--Event costs-->
            <v-card-title class="pb-0">Event Costs</v-card-title>
            <v-card-text>
              <v-list dense class="pa-0">
                <div
                  v-for="(cost, i) in filteredPackages.eventCosts
                    .slice()
                    .reverse()"
                  :key="i"
                >
                  <v-list-item class="pa-0" dense ripple two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ cost.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="overline"
                        v-if="cost.earlyBirdAmountInclVAT !== 0"
                      >
                        <div class="d-flex">
                          <div class="mr-2">Early Bird:</div>
                          <div class="green--text">
                            {{ cost.currencyCode }}
                            {{ cost.earlyBirdAmountInclVAT | currency }}
                          </div>
                          <div
                            v-if="
                              cost.earlyBirdAmountInclLCY !==
                              cost.earlyBirdAmountInclVAT
                            "
                            class="green--text"
                          >
                            KES
                            {{ cost.earlyBirdAmountInclLCY | currency }}
                          </div>
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="overline">
                        <div class="d-flex">
                          <span class="mr-2">
                            {{ cost.appliesType }}
                            {{
                              cost.appliesType.trim() === "" &&
                              cost.membershipCategory.trim() === ""
                                ? "Normal"
                                : ", "
                            }}
                            {{ getDescription(cost.membershipCategory) }} :
                          </span>
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <div class="green--text">
                          {{ cost.currencyCode }}
                          {{ cost.amountIncludingVAT | currency }}
                        </div>
                        <div
                          v-if="cost.amountInclLCY !== cost.amountIncludingVAT"
                          class="green--text"
                        >
                          KES
                          {{ cost.amountInclLCY | currency }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card-text>

            <!--Packages-->
            <v-card-title v-if="filteredPackages.otherpackages.length !== 0"
              >Packages</v-card-title
            >

            <v-card-text>
              <v-list dense v-if="filteredPackages.otherpackages.length !== 0">
                <template>
                  <h5 style="font-weight: 400">MEMBER</h5>
                  <div
                    v-for="(cost, i) in otherPackagesCategorization?.members
                      .slice()
                      .reverse()"
                    :key="i"
                  >
                    <v-list-item
                      dense
                      ripple
                      :two-line="cost.amountIncludingVAT !== 0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ cost.description }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="green--text overline text-wrap"
                          v-if="cost.amountIncludingVAT !== 0"
                        >
                          {{ cost.currencyCode }}
                          {{ cost.amountIncludingVAT | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <template>
                  <h5 style="font-weight: 400">Non-Member</h5>
                  <div
                    v-for="(cost, i) in otherPackagesCategorization?.nonMembers
                      .slice()
                      .reverse()"
                    :key="i"
                  >
                    <v-list-item
                      dense
                      ripple
                      :two-line="cost.amountIncludingVAT !== 0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ cost.description }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="green--text overline text-wrap"
                          v-if="cost.amountIncludingVAT !== 0"
                        >
                          {{ cost.currencyCode }}
                          {{ cost.amountIncludingVAT | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
              </v-list>
            </v-card-text>

            <v-card-title> Contact information </v-card-title>

            <v-card-text>
              <h4>Contact Person</h4>
              <p>{{ event.contactPersonName }}</p>

              <h4>Phone</h4>
              <p>{{ event.contactPersonPhoneNo }}</p>

              <h4>Email</h4>
              <p>{{ event.contactPersonEmail }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="mb-16">
        <v-col cols="12">
          <div class="d-flex justify-start">
            <v-img
              :height="$vuetify.breakpoint.mobile ? '100%' : '100'"
              v-for="(sponsor, i) in event.eventSponsor"
              :key="i"
              contain
              width="10%"
              class="align-self-start"
              :src="url + sponsor.base64Picture"
            />
          </div>
        </v-col>
        <v-col cols="12" style="position: relative; z-index: 1">
          <v-card class="my-4" id="map">
            <div style="height: 400px; width: 100%">
              <l-map
                :zoom="leafMap.zoom"
                :center="leafMap.center"
                :options="leafMap.mapOptions"
                style="height: 100%"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
              >
                <l-tile-layer
                  :url="leafMap.url"
                  :attribution="leafMap.attribution"
                />

                <l-marker :lat-lng="leafMap.withPopup">
                  <l-popup>
                    <div>I am a popup</div>
                  </l-popup>
                </l-marker>
              </l-map>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <PreviewDialog
      :dialog="dialog"
      @close-dialog="closeDialog"
      :document="document"
    />
  </v-container>
</template>

<script>
import { helper } from "../../../utilities";
import eventMixin from "../eventMixin";
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import PreviewDialog from "./PreviewDialog.vue";
import CPDbadge from "../views/CPDbadge.vue";
import EventCountdown from "./EventCountdown.vue";
import bookingMixin from "./Booking/bookingMixin";

// Fix for icon Markers
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "EventView",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    PreviewDialog,
    CPDbadge,
    EventCountdown,
  },
  mixins: [eventMixin, bookingMixin],
  data: function () {
    return {
      delegateCategory: "Member",
      map: {
        lat: "",
        long: "",
      },
      dialog: false,
      bookingTypeDialog: false,
      document: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch(
        "Events/getEventMeta",
        v.$root.decryptRoute(to).params.no
      );
      v.$store.dispatch("Events/getEvent", v.$root.decryptRoute(to).params.no);
      v.$store.dispatch("Events/getMembershipCategories");
    });
  },

  mounted() {
    // scroll to top of page
    window.scrollTo(0, 0);
  },

  computed: {
    cpd_caption() {
      return process.env.VUE_APP_CPD_CAPTION;
    },

    event() {
      return this.$store.getters["Events/event"];
    },

    leafMap() {
      //-1.2832533,36.8172449

      let latLong = latLng(
        parseFloat(this.event.gPSLatitude),
        parseFloat(this.event.gPSLongitude)
      );

      return {
        zoom: 13,
        center: latLong,
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLong,
        withTooltip: latLong,
        currentZoom: 11.5,
        currentCenter: latLong,
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5,
        },
        showMap: true,
      };
    },

    filteredPackages() {
      let eventpackages = {
        eventCosts: [],
        otherpackages: [],
      };

      if (this.event)
        this.event.eventPackageCost
          ? this.event.eventPackageCost.forEach((cost) => {
              if (cost.defaultPackage || cost.mandatory) {
                eventpackages.eventCosts.push(cost);
              }

              if (!cost.mandatory) eventpackages.otherpackages.push(cost);
            })
          : [];

      return eventpackages;
    },

    decryptedRoute() {
      return this.$root.decryptRoute(this.$route);
    },

    membershipCategories() {
      return this.$store.getters["Events/membershipCategories"];
    },

    hasCorporateOption: function () {
      return this.filteredPackages.eventCosts.some(
        (cost) => cost.appliesType === "Corporate"
      );
    },
    otherPackagesCategorization() {
      const membersPackages = this.filteredPackages?.otherpackages?.filter(
        (pckg) => {
          return pckg.appliesType === "Member";
        }
      );
      const nonMembersPackages = this.filteredPackages?.otherpackages?.filter(
        (pckg) => {
          return pckg.appliesType === "Non-Member";
        }
      );
      return { members: membersPackages, nonMembers: nonMembersPackages };
    },
  },

  methods: {
    fileMeta: function (file) {
      return {
        icon: helper.getFileIcon(file),
        color: helper.getFileIconColor(file),
      };
    },

    isEarlyBird: function (eventPackage) {
      return new Date(eventPackage.earlyBirdCutoffDate) > new Date();
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },

    centerUpdate(center) {
      this.currentCenter = center;
    },

    images(number) {
      return require(`@/assets/images/picture${number}.jpg`);
    },

    closeDialog: function (val) {
      this.dialog = val;
    },

    getDescription(item) {
      const category = this.membershipCategories.filter(
        (category) => category.Code === item
      )[0];
      return category ? category.Description : "";
    },
    addDelegate(bookingType = "Individual") {
      this.bookingTypeDialog = false;
      this.$root.routeTo({
        name: "DelegateCard",
        params: {
          no: this.event.no,
        },
        query: {
          bookingNo: this.booking.bookingNo
            ? this.booking.bookingNo
            : undefined,
          bookingType: bookingType,
        },
      });
    },
  },
};
</script>
