var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{attrs:{"dense":"","light":"","flat":""},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[_c('v-expansion-panel',{on:{"click":function($event){$event.preventDefault();return _vm.onClick()}}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onClick()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" $expand ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.expanded === 0 ? "Hide" : "Show")+" Event Details")])])]},proxy:true}])},[_c('div',{staticClass:"text-lg-h6 primary--text"},[_vm._v(" Booking: "+_vm._s(_vm.booking ? _vm.booking.bookingNo : "")+"   "),_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.booking.status === 'Cancelled'
              ? 'grey darken-1'
              : 'green darken-1'}},[_vm._v(" "+_vm._s(_vm.booking.status))])],1),_c('v-spacer'),(_vm.booking.status === 'Open')?_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.cancelBooking()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Cancel booking ")],1):_vm._e(),(
          _vm.booking.proformaInvoiceNo &&
          _vm.booking.proformaInvoiceNo.length !== 0 &&
          _vm.booking.postedInvoiceNo.length === 0
        )?_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.booking.status === 'Cancelled',"dark":"","small":"","color":"orange darken-3"},on:{"click":function($event){return _vm.$root.routeTo({
            name: 'invoice',
            params: { bookingNo: _vm.booking.bookingNo },
          })}}},[_vm._v(" View Proforma Invoice "),_c('v-icon',{staticClass:"mx-1",attrs:{"size":"25","left":""}},[_vm._v("arrow_forward")])],1):_vm._e(),(_vm.booking.postedInvoiceNo && _vm.booking.postedInvoiceNo.length !== 0)?_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.booking.status === 'Cancelled',"dark":"","small":"","color":"orange darken-3"},on:{"click":function($event){return _vm.viewInvoice(_vm.booking.postedInvoiceNo, _vm.booking.bookingNo)}}},[_vm._v(" Print Invoice "),_c('v-icon',{staticClass:"mx-1",attrs:{"size":"25","left":""}},[_vm._v("arrow_forward")])],1):_vm._e(),(_vm.booking.paid)?_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","small":"","color":"blue darken-3","width":"10%"},on:{"click":function($event){return _vm.$root.routeTo({
            name: 'receipt',
            params: { receiptNo: _vm.booking.receiptNo },
          })}}},[_vm._v(" Print Receipt "),_c('v-icon',{staticClass:"mx-4",attrs:{"size":"25","left":""}},[_vm._v(" arrow_forward ")])],1):_vm._e()],1),_c('v-expansion-panel-content',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12","lg":"4"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Event")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.cpdEvent.description)+" on "+_vm._s(_vm.cpdEvent.startDate)+" at "+_vm._s(_vm.cpdEvent.location)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12","lg":"4"}},[_c('v-list-item',{attrs:{"two-line-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Billed To")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.booking.names))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12","lg":"4"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Contact")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.booking.address))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.maskString(_vm.booking.email))+", "+_vm._s(_vm.maskString(_vm.booking.phoneNo)))])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }