export default {
  computed: {
    countries() {
      return this.$store.getters["Events/countries"];
    },
    counties() {
      return this.$store.getters["Events/counties"];
    },
    disciplines() {
      return this.$store.getters["Events/disciplines"];
    },
    disciplineRequired() {
      return process.env.VUE_APP_DISCIPLINE_REQUIRED;
    },
    eventDelegate() {
      return this.$store.getters["Events/eventDelegate"];
    },
    event() {
      return this.$store.getters["Events/event"];
    },
    booking() {
      return this.$store.getters["Events/booking"];
    },
    displaySelectedAmount() {
      return this.$store.getters["Events/displaySelectedAmount"];
    },
    decryptedRoute() {
      return this.$root.decryptRoute(this.$route);
    },

    isCorporateBooking: function () {
      return this.decryptedRoute.query.bookingType === "Corporate";
    },

    maxDelegates: function () {
      const defaultPackage =
        this.event &&
        this.event.eventPackageCost &&
        this.event.eventPackageCost.length
          ? this.event.eventPackageCost.find((cost) => cost.defaultPackage && cost.appliesType === 'Corporate')
          : null;
      return defaultPackage ? defaultPackage.quantity : 0;
    },

    canAddDelegate: function () {
      return (
        !this.isCorporateBooking ||
        (this.isCorporateBooking &&
          this.eventDelegate.length < this.maxDelegates)
      );
    },
  },
  methods: {
    filteredCounties(code){
      this.countiesList = this.counties.filter( function (county) {
        return code === county.countryCode;
      });
    },
    getCurrencies() {
      const currencies = [];
      if (this.event) {
        this.event.eventPackageCost.forEach((el) => {
          if (!currencies.includes(el.currencyCode))
            currencies.push(el.currencyCode);
        });
      }
      return currencies;
    },
    closeDialog: function () {
      this.$router.replace({
        name: "booking",
        params: {
          no: this.$route.params.no,
        },
        query: {
          bookingType: this.$route.query.bookingType,
          bookingNo: this.$route.query.bookingNo
            ? this.$route.query.bookingNo
            : undefined,
        },
      });
    },
    maskString(string) {
      return string.replace(/.(?=.{3})/g, "*");
    },
  },
};
